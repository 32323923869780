import { FC, useEffect } from 'react';
import Script from 'next/script';

import { ResolvedContextData } from '@common/defaults';
import { useContextData } from '@common/useContextData';
import { DateTimeDuration } from '@common/utils/DateTimeDuration';

interface Props {}

declare global {
    interface Window {
        dataLayer?: Object[];
    }
}

const getUserProperties = (contextData: ResolvedContextData): Record<string, string> => {
    const userProperties: Record<string, string> = {};

    if (contextData.experimentGroup) userProperties['experiment_group'] = contextData.experimentGroup;

    if (contextData.user?.id) {
        userProperties['logged_in'] = 'true';
        if (contextData.user.role) userProperties['user_role'] = contextData.user.role;
        if (contextData.user.bettingEnabled === true) {
            userProperties['betting_enabled'] = 'true';
        } else if (contextData.user.bettingEnabled === false) {
            userProperties['betting_enabled'] = 'false';
        } else {
            userProperties['betting_enabled'] = 'unknown';
        }
        if (contextData.user.birthDay) {
            const age = new DateTimeDuration(new Date(contextData.user.birthDay));
            userProperties['is_24'] = age.years >= 24 ? 'true' : 'false';
        } else {
            userProperties['is_24'] = 'unknown';
        }
    } else {
        userProperties['logged_in'] = 'false';
    }

    return userProperties;
};

export const GoogleAnalytics: FC<Props> = () => {
    const contextData = useContextData();
    const hasGaID = contextData.context.gaID || contextData.platform.gaID;

    useEffect(() => {
        if (!hasGaID) return;
        sendGAEvent('set', 'debug_mode', contextData.env.APP_ENV === 'production' ? 'false' : 'true');
        if (contextData.userID) {
            sendGAEvent('set', 'user_id', contextData.userID);
        }
        sendGAEvent('set', 'user_properties', getUserProperties(contextData));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!hasGaID) return null;

    return (
        <>
            <Script id="ga-init">{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('consent', 'default', {
                  'ad_storage': 'denied',
                  'ad_user_data': 'denied',
                  'ad_personalization': 'denied',
                  'analytics_storage': 'granted',
                  'wait_for_update': 1500,
                });
                gtag('js', new Date());
                ${contextData.context.gaID ? `gtag('config', '${contextData.context.gaID}');` : ''}
                ${contextData.platform.gaID ? `gtag('config', '${contextData.platform.gaID}');` : ''}
            `}</Script>
            <Script
                id="ga-script"
                src={`https://www.googletagmanager.com/gtag/js?id=${
                    contextData.context.gaID || contextData.platform.gaID
                }`}
                async
            />
        </>
    );
};

export function sendGAEvent(..._args: Object[]): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
}
