'use client';

import { memo, ReactNode, ReactPortal as ReactPortalType, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export const Component = ({
    containerID,
    children,
}: {
    containerID: string;
    children: ReactNode;
}): ReactPortalType | null => {
    const [mounted, setMounted] = useState(false);
    const ref = useRef<Element | null>(null);
    useEffect(() => {
        const element = document.querySelector('#' + containerID);
        ref.current = element;
        setMounted(!!element);

        const handleContainerChange = () => {
            const updatedElement = document.querySelector('#' + containerID);
            ref.current = updatedElement;
            setMounted(!!updatedElement);
        };

        window.addEventListener('resize', handleContainerChange);
        return () => {
            window.removeEventListener('resize', handleContainerChange);
        };
    }, [containerID]);

    return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export const ReactPortal = memo(Component);
