'use client';

import { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'next-themes';

import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { ContextData } from '@common/defaults';
import { NextContext } from '@common/useContextData';

interface Props extends PropsWithChildren {
    contextData: ContextData;
    theme: string;
}

export const ContextProviders: FC<Props> = ({ children, contextData, theme }: Props) => {
    return (
        <ThemeProvider forcedTheme={theme}>
            <NextContext.Provider value={contextData}>
                <SupertokensWrapper>{children}</SupertokensWrapper>
            </NextContext.Provider>
        </ThemeProvider>
    );
};
