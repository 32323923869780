import { FC, PropsWithChildren } from 'react';

import { ReactPortal } from '@common/atoms/ReactPortal/ReactPortal';

interface Props extends PropsWithChildren {}

export const DeprecatedRoot: FC<Props> = ({ children }: Props) => {
    return (
        <main id="main" className="_gp-redesign" suppressHydrationWarning>
            <div className="content _gp-redesign full-width " suppressHydrationWarning>
                <div id="_next_body" suppressHydrationWarning>
                    <ReactPortal containerID="__next">{children}</ReactPortal>
                    <div id="__next" suppressHydrationWarning />
                </div>
            </div>
        </main>
    );
};
