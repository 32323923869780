import { PlatformID } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { getHostByHostname, Host } from '@common/utils/HostUtil';

export const getTheme = (contextData: ContextData, hostname: string, platformID: PlatformID): string => {
    // if (router.route === '/liveness') return 'vp';
    if (getHostByHostname(hostname) === Host.VP_BE) return 'vpbe';
    else if (platformID) return platformID;
    else if (process.env.NEXT_PUBLIC_THEME) return process.env.NEXT_PUBLIC_THEME;
    else if (contextData) return contextData.platform.id;
    else if (process.env.APP_ENV === 'development' || process.env.CI === 'true') return 'vp';
    else {
        logger.warn(`Theme was not found for hostname '${hostname}'. Defaulting to 'vp'.`);
        return 'vp';
    }
};
